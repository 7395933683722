<template>
    <div>
        <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
            <span v-if="snackbar_msg!=null">{{ snackbar_msg }}</span>
            <v-btn dark text @click="snackbar = false">Close</v-btn>
        </v-snackbar>
        <v-row class="bradcrumb">
            <router-link :to="{name: 'role-links' }" class="root">
                Dashboard
            </router-link>
            /
            <router-link :to="{name: 'admin-master' }" class="root">
                Admin
            </router-link>
            <span class="child">/Add Library Policy </span>
        </v-row>
        <br />
        <v-card class="card">
            <v-card-title class="heading justify-center">
                <span>Add Library Policy</span>
            </v-card-title>
            <v-card-text>
                <div class="d-flex justify-content-end p-2">
                    <div>
                        <v-btn class="mb-2 button" @click="addform">Add Library Policy</v-btn>
                    </div>
                </div>
                <template>
                    <v-data-table :headers="headers" :items="librarypolicy" hide-default-footer disable-pagination  group-by="policy_type" :items-per-page="5" class="elevation-1">
                        <template v-slot:item.edit="{ item }">
                            <v-icon  @click="editItem(item)">
                                mdi mdi-pencil-box
                            </v-icon>
                        </template>
                        <template v-slot:item.actions="{ item }">
                            <!-- <v-btn :loading="edit_btn_load" @click="editItem(item)" class="link" color="info"> <v-icon title="Activate/Deactivate the User Type">mdi-recycle</v-icon></v-btn> -->
                            <v-switch v-model="item.isactive" @change="isactiveItem(item)"></v-switch>
                        </template>
                    </v-data-table>
                </template>
            </v-card-text>
        </v-card>
        <template>
            <v-toolbar flat>
                <v-dialog v-model="addDialog" max-width="500px">
                    <v-card>
                        <v-card-title class="heading">
                            <span class="headline">Add Library Policy</span>
                        </v-card-title>
                        <v-form ref="form">
                            <v-col class="text">
                               <v-col class="fields" style="text-align: start;">
                                    <label class="add-text">policy Type</label>
                                        <v-select outlined dense :items="policy_type_list" item-text="name" item-value="id" v-model="selected_policy_type"></v-select>                
                                </v-col>
                                <v-col class="fields" style="text-align: start;">
                                    <label class="add-text">User Type</label>
                                    <v-combobox v-model="selectedusertype" :items="usertypelist" item-text="usertype" outlined return-object dense :rules="[rules.required]" clearable required></v-combobox>
                                </v-col>
                                <v-col class="fields" style="text-align: start;">
                                    <label class="add-text">Book Borrowed Limit</label>
                                    <v-text-field v-model="bookborrowlimit" dense outlined :rules="[rules.required]"></v-text-field>
                                </v-col>
                                <v-col class="fields" style="text-align: start;">
                                    <label class="add-text">Maximum Number Of Book Holding Days</label>
                                    <v-text-field v-model="bookholdingdays" dense outlined :rules="[rules.required]"></v-text-field>
                                </v-col>
                                <v-col class="fields" style="text-align: start;">
                                    <label class="add-text">Fine rate per day</label>
                                    <v-text-field v-model="fineperday" dense outlined :rules="[rules.required]"></v-text-field>
                                </v-col>
                                <v-col class="fields" style="text-align: start;">
                                    <label class="add-text">Book reservation validity days</label>
                                    <v-text-field v-model="reservationvalidityday" dense outlined :rules="[rules.required]"></v-text-field>
                                </v-col>
                                <v-col class="fields" style="text-align: start;">
                                    <label class="add-text">Book reservation limit</label>
                                    <v-text-field v-model="reservationlimit" dense outlined :rules="[rules.required]"></v-text-field>
                                </v-col>
                            </v-col>
                        </v-form>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn class="button-cancel" text @click="addDialog=!addDialog">
                                Cancel
                            </v-btn>
                            <v-btn class="button" text @click="savepolicy()">
                                Save
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <v-dialog v-model="dialog" max-width="500px">
                    <v-card>
                        <v-card-title class="heading">
                            <span class="headline">Edit Library Policy</span>
                        </v-card-title>

                        <v-card-text>
                            <v-container>
                                <v-row>
                                <v-col class="fields">
                                    <label>policy Type</label>
                                        <v-select outlined dense disabled :items="policy_type_list" item-text="name" item-value="id" v-model="editedItem.selected_policy_type"></v-select>                
                                </v-col>
                                </v-row>
                                <v-row>
                                    <v-col class="fields">
                                        <label>Role</label>
                                        <v-combobox v-model="editedItem.usertypelist" :items="usertypelist" item-text="usertype" return-object disabled outlined dense></v-combobox>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col class="fields">
                                        <label>Book Borrowed Limit</label>
                                        <v-text-field type="number" v-model="editedItem.number_of_books" outlined dense></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col class="fields">
                                        <label>Maximum Number Of Book Holding Days</label>
                                        <v-text-field type="number" outlined dense v-model="editedItem.number_of_days"></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col class="fields">
                                        <label>Fine rate per day</label>
                                        <v-text-field type="number" outlined dense v-model="editedItem.fine_rate_per_day"></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col class="fields">
                                        <label>Book reservation validity days</label>
                                        <v-text-field type="number" outlined dense v-model="editedItem.reservation_validity_days"></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col class="fields">
                                        <label>Book reservation limit</label>
                                        <v-text-field type="number" outlined dense v-model="editedItem.book_reservation_limit"></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn class="button-cancel" text @click="close">
                                Cancel
                            </v-btn>
                            <v-btn class="button" text @click="save">
                                Save
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-toolbar>
        </template>
    </div>
</template>

<script>
    import axios from "axios";
    import "@/assets/css/color.css"; // css needs to be imported for each dashboard

    export default {
        data: () => ({
            selected_policy_type:"",
            policy_type_list:[],
            addDialog: false,
            snackbar_msg: "",
            snackbar: false,
            color: "",
            usertype: "",
            selectedchkbox: [],
            rolelist: [],
            librarypolicy: [],
            usertypelist: [],
            selectedusertype: "",
            bookborrowlimit: "",
            bookholdingdays: "",
            fineperday: "",
            reservationvalidityday: "",
            reservationlimit: "",
            libpolicyid: "",
            memberId: "",
            usernamemessage: "",
            rules: {
                required: (value) => !!value || "Required.",
            },
            dialog: false,
            headers: [
                { text: "Role", value: "usertypelist.usertype" },
                 { text: "Policy Type", value: "policy_type1" },
                { text: "Book Borrowed Limit", value: "number_of_books" },
                { text: "Maximum Number Of Book Holding Days", value: "number_of_days" },
                { text: "Fine rate per day", value: "fine_rate_per_day" },
                { text: "Book reservation validity days", value: "reservation_validity_days" },
                { text: "Book reservation limit", value: "book_reservation_limit" },
                { text: "Edit", value: "edit" },
                { text: "On/Off", value: "actions" },
            ],
            editedIndex: -1,
            editedItem: {
                selectedusertype:'',
                usertypelist:null,
                number_of_books: '',
                number_of_days: '',
                fine_rate_per_day:'',
                reservation_validity_days:'',
                book_reservation_limit:'',
                libpolicyid:'',
                selected_policy_type:"",
            },
            defaultItem: {
                usertypelist: null,
                number_of_books: "",
                number_of_days: "",
                fine_rate_per_day: "",
                reservation_validity_days: "",
                book_reservation_limit: "",
                libpolicyid: "",
                 selectedusertype: "",
                 selected_policy_type:"",
            },
        }),
        mounted() {
            this.onLoad();
                this.getpolicy_type_list();
        },
        computed: {
            formTitle() {
                return "Edit Item";
            },
        },
        // watch: {
        //     dialog (val) {
        //     val || this.close()
        //     },
        // },
        methods: {
              getpolicy_type_list()
    {
      axios
        .get("/Librarian/get_policy_type_list")
        .then((res) => {
          if (res.data.msg == "200") {
           this.policy_type_list=res.data.policy_list    ;        // this.onload1();
          }
        })
    },
            addform() {
                this.addDialog = true;
            },
            showSnackbar(clr, msg) {
                this.snackbar = true;
                this.color = clr;
                this.snackbar_msg = msg;
            },
            savepolicy: function () {
                if (this.$refs.form.validate()) {
                    const data = {
                        policy_type:this.selected_policy_type,
                        selectedusertype: this.selectedusertype,
                        bookborrowlimit: this.bookborrowlimit,
                        bookholdingdays: this.bookholdingdays,
                        fineperday: this.fineperday,
                        reservationvalidityday: this.reservationvalidityday,
                        reservationlimit: this.reservationlimit,
                    };
                    axios
                        .post("/admin/savelibrarypolicy", data)
                        .then((res) => {
                            if (res.data.msg == "200") {
                                this.addDialog = false;
                                this.onLoad();
                            }
                        })
                        .catch((error) => {
                            this.showSnackbar("#b71c1c", "Something went wrong!!!");
                            window.console.log(error);
                        });
                }
            },
            findUsertype: function () {
                axios
                    .post("admin/fetchUserType", this.selecteduser)
                    .then((res) => {
                        //console.log("res------->"+res.data.userType)
                        if (res.data.msg == "200") {
                            (this.usertype = res.data.usertype), (this.userrole = res.data.userrole), (this.rolelist = res.data.rolelist), (this.selectedchkbox = []);
                        }
                    })
                    .catch((error) => {
                        window.console.log(error);
                    });
            },
            onLoad: function () {
                axios
                    .get("admin/fetchAllUsertype")
                    .then((res) => {
                        //console.log("res------->"+res.data.userType)
                        if (res.data.msg == "200") {
                            this.usertypelist = res.data.usertypelist;
                            this.librarypolicy = res.data.librarypolicy;
                        }
                    })
                    .catch((error) => {
                        window.console.log(error);
                    });
            },
            isactiveItem: function (item) {
                console.log("in isactive item----------", item);
                axios
                    .post("/admin/isactivelibrarypolicy", item)
                    .then((res) => {
                        if (res.data.msg == "200") {
                            this.showSnackbar("#4CAF50", "Successfully updated library policy!!");
                        }
                    })
                    .catch((error) => {
                        this.showSnackbar("#b71c1c", "Something went wrong!!!");
                        window.console.log(error);
                    });
            },
            editItem: function (item) {
                this.dialog = true;

                this.editedIndex = this.librarypolicy.indexOf(item);
                console.log("in edit item----------", item);
                this.editedItem = Object.assign({}, item);

                console.log("in edit item----------", this.editedItem);
                console.log(this.dialog);
            },
            close() {
                this.dialog = false;
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem);
                    this.editedIndex = -1;
                });
            },
            save() {
                //alert(this.editedItem.number_of_books)
                const data={
                    libpolicyid:parseInt(this.editedItem.libpolicyid), 
                    number_of_books:parseInt(this.editedItem.number_of_books),
                    number_of_days:parseInt(this.editedItem.number_of_days),
                    fineperday:parseInt(this.editedItem.fine_rate_per_day), 
                    reservationlimit:parseInt(this.editedItem.book_reservation_limit), 
                    reservationvalidityday:parseInt(this.editedItem.reservation_validity_days), 
                    policy_type:this.editedItem.selected_policy_type
                }
                axios
                    .post("/admin/updateLibraryPolicy",data)
                    .then((res) => {
                        //console.log("res------->"+res.data.userType)
                        if (res.data.msg == "200") {
                            if (res.data.code == 1) this.showSnackbar("#4CAF50", "Successfully updated user!!");
                            else this.showSnackbar("#4CAF50", "Successfully updated user!!");
                            this.onLoad();
                        }
                    })
                    .catch((error) => {
                        this.showSnackbar("#b71c1c", "Something went wrong!!!");
                        window.console.log(error);
                    });
                this.close();
            },
        },
    };
</script>
<style scoped>
    .heading {
        background-color: #3f51b5;
        padding: 0.3rem;
        color: white;
    }
    .v-icon {
        cursor: pointer;
    }
    .text >>> .v-input__slot {
        background: white !important;
        border-color: hsla(190, 99%, 30%, 1) !important;
    }
    .check >>> .v-input__slot {
        background: white !important;
        border-color: hsla(190, 99%, 30%, 1) !important;
    }
    .p-btn {
        padding: 0px 12px 12px 12px;
    }
    .add-section {
        display: block;
        text-align: -webkit-center;
    }
    .checkbox {
        margin-left: 1.5rem;
        margin-right: 1.5rem;
    }
</style>
